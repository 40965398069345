
import { Vue, Prop } from 'vue-property-decorator';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

declare const window: any;
(function() {
    // Load the script
    const script = document.createElement("SCRIPT") as HTMLScriptElement;
    script.src = 'https://venx.b-cdn.net/underconstruction/jquery-2.2.3.min.js';
    script.type = 'text/javascript';
    script.onload = function() {
        const $ = window.jQuery;
        (function() {
            // Load the script
            const script = document.createElement("SCRIPT") as HTMLScriptElement;
            script.src = 'https://venx.b-cdn.net/underconstruction/jquery.vide.min.js';
            script.type = 'text/javascript';
            document.getElementsByTagName("head")[0].appendChild(script);
        })();
    };
    document.getElementsByTagName("head")[0].appendChild(script);
})();

export default class UnderConstructionApp extends Vue {
  @Prop() content = window.content;
  @Prop() body = documentToHtmlString(window.content.body);
  @Prop() footer = documentToHtmlString(window.content.footer);
}
