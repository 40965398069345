import Vue from 'vue'
import App from './App.vue'
import UnderConstructionApp from './App_UnderConstruction.vue'
import store from './store'

declare const window: any;

Vue.config.productionTip = false;

switch(window.contenttype) {
  case 'underConstruction':
    new Vue({
      store,
      render: h => h(UnderConstructionApp)
    }).$mount('#app');
    break;
  default:
    new Vue({
      store,
      render: h => h(App)
    }).$mount('#app');
}
